import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { secondary } from '../../../utils/theme';
import Button from '../Button';
import CloseButton from './CloseButton';

export type ModalProps = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    title?: string;
    children: ReactNode;
    okText?: string;
    okDisabled?: boolean;
    cancelText?: string;
    onOk?: (e: React.MouseEvent<HTMLButtonElement> | KeyboardEvent) => void;
    onCancel?: (e: React.MouseEvent<HTMLButtonElement> | KeyboardEvent) => void;
    className?: string;
    noAction?: boolean;
    dataCy?: string;
    footer?: ReactNode;
};

const Modal = ({
    open,
    setOpen,
    title,
    children,
    okText,
    okDisabled,
    cancelText,
    onOk,
    onCancel,
    className,
    noAction = false,
    dataCy,
    footer,
}: ModalProps) => {
    const { t } = useTranslation('common');

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (open && event.key === 'Enter' && !(event.shiftKey || event.ctrlKey || event.altKey || event.metaKey)) {
                onOk?.(event);
                event.preventDefault();
            }
        };

        document.addEventListener('keypress', handleKeyPress);

        return () => {
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, [open, onOk]);

    return (
        <Transition show={open}>
            <Dialog className="relative z-10" data-cy={dataCy} onClose={() => setOpen(false)}>
                <TransitionChild
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <DialogPanel
                                // eslint-disable-next-line max-len
                                className={`relative w-full transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-auto sm:p-6 ${className}`}
                            >
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <CloseButton onClick={() => setOpen(false)} />
                                </div>
                                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                    <DialogTitle
                                        as="h3"
                                        className={`h-6 text-base font-semibold leading-6 ${secondary.textColor}`}
                                    >
                                        {title}
                                    </DialogTitle>
                                    <div className="mt-2">{children}</div>
                                </div>
                                {footer}
                                {!noAction && !footer && (
                                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        <Button
                                            className="ml-3"
                                            design="primary"
                                            disabled={okDisabled}
                                            onClick={onOk}
                                            size="s"
                                        >
                                            {okText || t('common:modal.ok')}
                                        </Button>
                                        <Button className="ml-3" design="secondary" onClick={onCancel} size="s">
                                            {cancelText || t('common:modal.cancel')}
                                        </Button>
                                    </div>
                                )}
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};
export default Modal;
