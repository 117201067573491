import { Route, Routes, useLocation } from 'react-router-dom';
import RedirectTo from './RedirectTo';
import NotFound from './components/blocks/NotFound';
import CenteredLayout from './components/layouts/CenteredLayout';
import OrganizationLayout from './components/layouts/OrganizationLayout';
import { useUser } from './contexts/UserSession';
import GroupDetail from './pages/GroupDetails';
import Groups from './pages/Groups';
import InvitationPage from './pages/Invitation';
import LeadDetailsPage from './pages/LeadDetails';
import LeadsPage from './pages/Leads';
import LoginPage from './pages/LoginPage';
import NewLeadsPage from './pages/NewLeads';
import OrganizationPage from './pages/Organization';
import ProfilePage from './pages/ProfilePage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import SignOutPage from './pages/SignOutPage';
import Users from './pages/Users';
import runtime from './runtime';

const AppRoutes = () => {
    const user = useUser();
    const location = useLocation();

    if (!runtime.organization) {
        return <NotFound homePath={null} />;
    }

    const invitationRoute = (
        <Route element={<CenteredLayout />} path="/invitation/*">
            <Route element={<InvitationPage />} index />
            <Route element={<NotFound homePath="/invitation/" />} path="*" />
        </Route>
    );

    if (!user) {
        return (
            <Routes>
                {invitationRoute}
                <Route element={<CenteredLayout />} path="/login/*">
                    <Route element={<LoginPage />} index />
                    <Route element={<ResetPasswordPage />} path="resetPassword" />
                    <Route element={<NotFound homePath="/login/" />} path="*" />
                </Route>
                <Route element={<RedirectTo state={{ from: location.pathname }} to="/login/" />} path="*" />
            </Routes>
        );
    }

    return (
        <Routes>
            <Route element={<RedirectTo to="/" />} path="/login/*" />
            {invitationRoute}
            <Route element={<OrganizationLayout />} path="*">
                <Route element={<RedirectTo to="/leads" />} index />
                <Route element={<LeadsPage />} path="leads" />
                <Route element={<NewLeadsPage />} path="leads/new" />
                <Route element={<LeadDetailsPage />} path="leads/:id" />
                <Route element={<OrganizationPage />} path="organization" />
                <Route element={<Users />} path="users" />
                <Route element={<Groups />} path="groups" />
                <Route element={<GroupDetail />} path="groups/:id" />
                <Route element={<ProfilePage />} path="profile" />
                <Route element={<SignOutPage />} path="signout" />
                <Route element={<NotFound homePath="/" />} path="*" />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
