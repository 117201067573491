import './dayjs.extend';
import { ApolloProvider } from '@apollo/client';
import AppRoutes from './AppRoutes';
import apolloClient from './apolloClient';
import ConfirmModalProvider, { ConfirmModalCommon as ConfirmModal } from './contexts/ConfirmModal';
import UserSessionProvider from './contexts/UserSession';
import SubscriptionManager from './subscriptions';

const App = () => (
    <ApolloProvider client={apolloClient}>
        <UserSessionProvider>
            <ConfirmModalProvider>
                <SubscriptionManager />
                <AppRoutes />
                <ConfirmModal />
            </ConfirmModalProvider>
        </UserSessionProvider>
    </ApolloProvider>
);

export default App;
